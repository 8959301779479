class HhArtistteaser extends HTMLElement {

  constructor() {
    super();
    this.body = document.querySelector('body');
    this.logo = document.querySelector('.logo');
    this.artistsGrid = document.querySelector('.artists-grid');

    this.mouseEnterHandler = () => {
      this.update();
    }
    this.mouseEnterListener = this.addEventListener('mouseenter', this.mouseEnterHandler);

    this.mouseLeaveHandler = () => {
      this.reset();
    }
    this.mouseLeaveListener = this.addEventListener('mouseleave', this.mouseLeaveHandler);

    // console.log(this.mouseLeaveListener);

    // document.querySelector('body').addEventListener('mouseleave', (event) => {
    //   this.reset();
    // });
  }

  update() {
    if (!this.artistsGrid || (this.artistsGrid && this.artistsGrid.clientWidth === this.artistsGrid.scrollWidth)) {
      this.logo.firstname = this.firstname;
      this.logo.lastname = this.lastname;
      this.logo.url = this.url;
      document.documentElement.style.setProperty('--c-bg', this.colorbg);
      document.documentElement.style.setProperty('--c-text', this.colortext);

      this.body.classList.add('artists-active');
    }
  }

  reset() {
    if (!this.artistsGrid || (this.artistsGrid && this.artistsGrid.clientWidth === this.artistsGrid.scrollWidth)) {
      this.logo.firstname = this.logo.firstname_default;
      this.logo.lastname = this.logo.lastname_default;
      this.logo.url = this.logo.url_default;
      document.documentElement.style.setProperty('--c-bg', document.documentElement.style.getPropertyValue('--c-bg-default'));
      document.documentElement.style.setProperty('--c-text', document.documentElement.style.getPropertyValue('--c-text-default'));

      this.body.classList.remove('artists-active');
    }
  }

  get colortext() {
    return this.getAttribute('colortext');
  }

  get colorbg() {
    return this.getAttribute('colorbg');
  }

  get firstname() {
    return this.getAttribute('firstname');
  }

  get lastname() {
    return this.getAttribute('lastname');
  }

  get url() {
    return this.getAttribute('url');
  }

  disconnectedCallback() {
    this.removeEventListener('mouseenter', this.mouseEnterHandler);
    this.removeEventListener('mouseleave', this.mouseLeaveHandler);
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('hh-artistteaser', HhArtistteaser);
}
