import { gsap, TextPlugin } from "gsap/all";
gsap.registerPlugin(TextPlugin);

class HhLogo extends HTMLElement {

  constructor() {
    super();

    this.firstnameElement = this.querySelector('.logo-firstname');
    this.lastnameElement = this.querySelector('.logo-lastname');
    this.linkElement = this.querySelector('.logo-link');
  }

  get firstname_default() {
    return this.getAttribute('firstname_default');
  }

  get lastname_default() {
    return this.getAttribute('lastname_default');
  }

  get url_default() {
    return this.getAttribute('url_default');
  }

  get firstname() {
    return this.getAttribute('firstname');
  }

  set firstname(val) {
    if (val) {
      this.setAttribute('firstname', val);
      this.update();
    }
    else {
      this.removeAttribute('firstname', '');
    }
  }

  get lastname() {
    return this.getAttribute('lastname');
  }

  set lastname(val) {
    if (val) {
      this.setAttribute('lastname', val);
      this.update();
    }
    else {
      this.removeAttribute('lastname', '');
    }
  }

  get url() {
    return this.getAttribute('url');
  }

  set url(val) {
    if (val) {
      this.setAttribute('url', val);
      this.linkElement.href = this.url;
    }
    else {
      this.removeAttribute('url', '');
    }
  }

  update() {
    if (this.animationFirstname) {
      this.animationFirstname.kill();
    }
    this.animationFirstname = gsap.to(this.firstnameElement, {
      duration: 0.5,
      text: this.firstname,
      ease: "none",
    });

    if (this.animationLastname) {
      this.animationLastname.kill();
    }
    this.animationLastname = gsap.to(this.lastnameElement, {
      duration: 0.5,
      text: this.lastname,
      ease: "none",
    });
  }

  // static get observedAttributes() {
  //   return ['firstname', 'lastname', 'url'];
  // }

  // attributeChangedCallback(name, oldValue, newValue) {
  //   if (name == 'url' && newValue && newValue !== this.linkElement.href) {
  //     this.linkElement.href = newValue;
  //   }

  //   if (name == 'firstname' && newValue && newValue !== this.firstnameElement.textContent) {
  //     if (this.animationFirstname) {
  //       this.animationFirstname.kill();
  //     }
  //     this.animationFirstname = gsap.to(this.firstnameElement, {
  //       duration: 0.5,
  //       text: newValue,
  //       ease: "none",
  //     });
  //   }

  //   if (name == 'lastname' && newValue && newValue !== this.lastnameElement.textContent) {
  //     if (this.animationLastname) {
  //       this.animationLastname.kill();
  //     }
  //     this.animationLastname = gsap.to(this.lastnameElement, {
  //       duration: 0.5,
  //       text: newValue,
  //       ease: "none",
  //     });
  //   }
  // }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('hh-logo', HhLogo, );
}
