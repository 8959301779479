// import { scrollend } from 'scrollyfills';
// import Siema from "siema";
import { tns } from "tiny-slider"
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

class HhArtistsgrid extends HTMLElement {

  constructor() {
    super();

  }

  connectedCallback() {
    this.locked = true;
    setTimeout(() => {
      this.locked = false;
    }, 2000);

    this.body = document.querySelector('body');
    this.teasers = this.querySelectorAll('.artist-teaser');
    this.logo = document.querySelector('.logo');

    this.checkBreakpointElement = document.getElementById('check-breakpoint');
    this.currentBreakpoint = null;
    this.slider = null;

    window.addEventListener('resize', (event) => {
      let newBreakpoint = window.getComputedStyle(this.checkBreakpointElement, ':after').content;
      if (newBreakpoint != this.currentBreakpoint) {
        if (newBreakpoint == '"DESKTOP"') {
          if (this.slider && typeof this.slider.destroy == 'function') {
            this.slider.getInfo().container.classList.remove('touch-pan-x');
            this.slider.destroy();
          }
        }
        else {
          this.slider = tns({
            container: '.slider-wrapper',
            loop: true,
            nav: false,
            controls: false,
            swipeAngle: 45,
          });

          this.slider.events.on('transitionEnd', (info, eventName) => {
            this.update();
          });
        }

        this.currentBreakpoint = newBreakpoint;
      }
    });

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);


    const st = ScrollTrigger.create({
      // markers: true,
      trigger: this,
      start: "bottom 90%",
      end: "top top",
      id: 'artists-grid',
      onEnter: () => {
        this.update();
      },
      onEnterBack: () => {
        this.update();
      },
      onLeave: () => {
        this.reset();
      },
      onLeaveBack: () => {
        this.reset();
      },
    });
  }

  update() {
    if (!this.locked && this.clientWidth !== this.scrollWidth) {
      for (const teaser of this.querySelectorAll('.artist-teaser')) {
        if (teaser.getBoundingClientRect().left > 0) {
          document.documentElement.style.setProperty('--c-bg', teaser.colorbg);
          document.documentElement.style.setProperty('--c-text', teaser.colortext);
          this.logo.firstname = teaser.firstname;
          this.logo.lastname = teaser.lastname;
          this.logo.url = teaser.url;

          this.body.classList.add('artists-active');
          break;
        }
      }
    }
  }

  reset() {
    document.documentElement.style.setProperty('--c-bg', document.documentElement.style.getPropertyValue('--c-bg-default'));
    document.documentElement.style.setProperty('--c-text', document.documentElement.style.getPropertyValue('--c-text-default'));
    this.logo.firstname = this.logo.firstname_default
    this.logo.lastname = this.logo.lastname_default
    this.logo.url = this.logo.url_default

    this.body.classList.remove('artists-active');
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('hh-artistsgrid', HhArtistsgrid );
}
